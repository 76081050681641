.vc_col-sm-1-per-5 {
  float: left;
  position: relative;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@media (min-width: 768px) {
  .vc_column_container.vc_col-sm-1-per-5 {
    width: 20%; } }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.wpbb_about_page .wpbb_about_title {
  width: 100%;
  background: #4C60FA; }
  .wpbb_about_page .wpbb_about_title .wpbb-container {
    position: relative; }
    .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section {
      z-index: 2;
      position: relative;
      width: 70%;
      padding: 150px 15% 150px 15%; }
      .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section h1 {
        font-size: 130px;
        font-weight: 700;
        color: #fff;
        line-height: 1;
        margin: 0px; }
      .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section h3 {
        margin: 0px;
        line-height: 1;
        font-size: 20px;
        color: #F4D55A; }
      .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section h4 {
        width: 50%;
        line-height: 2;
        color: #DCE0FF;
        font-size: 18px;
        padding-top: 30px; }
      .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section .hero-button {
        margin-top: 30px;
        padding: 13px 40px;
        color: #fff;
        font-size: 16px;
        padding: 13px 40px;
        border: solid 3px #FFF;
        text-decoration: none;
        font-weight: 500;
        display: inline-block;
        -webkit-transition: all 333ms ease;
        transition: all 333ms ease;
        -webkit-box-shadow: 0 0 0 transparent;
        box-shadow: 0 0 0 transparent; }
        .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section .hero-button:hover {
          background: #FFF;
          color: #111; }
      .wpbb_about_page .wpbb_about_title .wpbb-container .wpbb-left-section .how-to-use {
        margin-top: 30px;
        color: #FFF;
        margin-left: 35px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        border-bottom: solid 2px #FFF;
        padding-bottom: 2px;
        -webkit-transition: all .15s;
        transition: all .15s; }

.wpbb_about_page .wpbb_about_plugin .wpbb-container {
  padding: 100px 15%; }
  .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-title h2 {
    margin: 0;
    color: #1d8fd8;
    font-weight: 400;
    font-size: 42px;
    line-height: 1; }
  .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-title h4 {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7;
    color: #888;
    margin: 5px 0px 0px 0px; }
  .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    grid-gap: 48px;
    margin-top: 50px; }
    .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li {
      position: relative;
      height: 200px;
      padding: 20px;
      margin: 0px;
      background: #fff; }
      .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li:hover {
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.1); }
      .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li {
        position: relative;
        height: 100%; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-img {
          transition: all .15s;
          position: absolute;
          width: 35px;
          height: 35px;
          border-radius: 100%;
          right: -10px;
          top: -10px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-price {
          padding: 1px 3px;
          border-radius: 100%;
          background: #FFCD8F;
          font-size: 15px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-title {
          width: 100%;
          margin: 0px;
          padding: 0px 0px 0px 0px;
          color: #323f4a;
          font-weight: bold;
          font-size: 14px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-dep {
          font-weight: 400;
          line-height: 1.5;
          color: #888;
          font-size: 14px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-get-plugin-now {
          text-align: center;
          position: absolute;
          bottom: 0px;
          width: 100%;
          padding: 8px 0px; }
          .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-get-plugin-now a {
            text-decoration: none;
            border-radius: 2px;
            color: #fff;
            background: #1D8FD8;
            padding: 8px 16px; }
            .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li .wpbb-get-plugin-now a:hover {
              background: #349ADC; }
      .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-content-li:hover + .wpbb-preview {
        display: block; }
      .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview {
        display: none;
        position: absolute;
        bottom: 0;
        left: 270px;
        width: 500px;
        z-index: 2;
        background: #333333;
        padding: 10px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-img {
          width: 500px;
          height: 250px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-title {
          color: #fff;
          font-size: 20px;
          margin: 0px; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-author {
          margin: 0px;
          color: #8a8a8a; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-price {
          text-align: right;
          color: #fff;
          font-size: 36px;
          font-weight: 700;
          margin: 0px;
          line-height: 1; }
        .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-dep {
          margin-top: 5px; }
          .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-dep p {
            margin: 0px;
            color: #8a8a8a;
            display: inline-block; }
          .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-dep p:nth-child(1) {
            width: 30%; }
          .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-dep p:nth-child(2) {
            width: 69%;
            text-align: right; }
          .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li .wpbb-preview .wpbb-preview-dep p:nth-child(3) {
            width: 100%;
            text-align: right; }
    .wpbb_about_page .wpbb_about_plugin .wpbb-container .wpbb-plugin-content .wpbb-list-ul .wpbb-list-li:nth-child(4n) .wpbb-preview {
      left: -520px; }
