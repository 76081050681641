body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.mainPlayer {
  width: 68vw;
  height: calc((100vw - 20vw) * 0.4825);
}
.mainImaPlayer {
  width: 99vw;
  height: calc((100vw - 20vw) * 0.5625);
}
@media screen and (max-width: 600px) {
  .mainPlayer {
    width: 79vw;
    height: calc((100vw - 20vw) * 0.5825);
  }
}