.blink_me {
    animation: blinker 0.5s linear infinite;
    font-size: 15px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/*========= For Modal Popup  ===============*/
.mega-model-popup {
    border-style: solid;
    border-width: 2px;
    border-radius: 0px;
    border-color: #333333;
    -webkit-box-shadow: 0 0 7px #404040;
    -o-box-shadow: 0 0 7px #404040;
    -ms-box-shadow: 0 0 7px #404040;
    box-shadow: 0 0 7px #404040;
}

.modal-popup-box .model-popup-btn {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

.mega-model-popup h2 {
    /*margin: 0 !important;*/
}

.mega-model-popup img {
    max-width: 100%;
    padding: 2px 0 0 2px;
}

.b-close {
    box-shadow: none;
    font: bold 131% sans-serif;
    position: absolute;
    right: -100px;
    top: -3px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.b-close>span {
    font-size: 30px;
    font-weight: 100;
}

@media only screen and (max-width: 768px) {
    .b-close {
        right: 5px;
        top: 5px;
    }

    .b-close img {
        width: 50px;
    }

    .mega-model-popup {
        margin-right: 10px;
        margin-top: 0 !important;
    }
}

/*========= For Info List  ===============*/
.mega-info-list li:first-child {
    padding-top: 0px !important;
}
