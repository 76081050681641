@font-face {
    font-family: Vaud;
    font-weight: 300;
    src: url('../fonts/Vaud300.woff2') format("woff2"), url('../fonts/Vaud300.woff') format("woff"), url('../fonts/Vaud300.ttf') format("truetype")
}

@font-face {
    font-family: Vaud;
    font-weight: 400;
    src: url('../fonts/Vaud400.woff2') format("woff2"), url('../fonts/Vaud400.woff') format("woff"), url('../fonts/Vaud400.ttf') format("truetype")
}

@font-face {
    font-family: Vaud;
    font-weight: 600;
    src: url('../fonts/Vaud600.woff2') format("woff2"), url('../fonts/Vaud600.woff') format("woff"), url('../fonts/Vaud600.ttf') format("truetype")
}

@font-face {
    font-family: Vaud;
    font-weight: 700;
    src: url('../fonts/Vaud700.woff2') format("woff2"), url('../fonts/Vaud700.woff') format("woff"), url('../fonts/Vaud700.ttf') format("truetype")
}

@font-face {
    font-family: Casual;
    src: url('../fonts/Casual.ttf') format("truetype")
}

@font-face {
    font-family: Courgette;
    src: url('../fonts/Courgette.ttf') format("truetype")
}

@font-face {
    font-family: Courier;
    src: url('../fonts/Courier.ttf') format("truetype")
}

@font-face {
    font-family: Lora;
    src: url('../fonts/Lora.ttf') format("truetype")
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto.ttf') format("truetype")
}

@font-face {
    font-family: Ubuntu Mono;
    src: url('../fonts/UbuntuMono.ttf') format("truetype")
}
