html {
	width: 100%;
	height: 100%;
	font-size: 62.5%; /* =10px */
}

#page{
	overflow: hidden;
	padding-top: 87px;
	&.opened-submenu{
		padding-top: 0;
		header.site-header {
			top: -119px;
			padding: 24px 0 30px;
		}
	}
}
body.page-child{
	#page{
		padding-top: 0;
/*		header.site-header {
			top: -119px;
			padding: 24px 0 55px;
		}*/
	}
}
.container{
	width: 100%;
	max-width: 1440px;
	padding-right: 65px;
	padding-left: 65px;
	&.w_780{
		max-width: 780px;
	}
	&.w_870{
		max-width: 1000px;
	}
	@media screen and (max-width: $small) {
		padding-right: 20px;
		padding-left: 20px;
	}
}
*,
*:after,
*:before {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	outline: none;
	color: inherit;
	a:visited,a:hover, a:focus, a:active {
		color: inherit;
	}
}

button {
	cursor: pointer
}

i,
.italic {
	font-style: italic
}

strong,
b {
	font-weight: $bold
}

em {
	font-style: italic
}

form {
	margin: 0;
}

input {
	font-family: $fontFamily;
}

%selection {
	color: $baseColor;
	background-color: $golden-gate;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

::selection {
	@extend %selection;
}

input[type=text]::-ms-clear {
	display: none;
}

small {
	font-size: 85%;
}

figure {
	margin: 0;
}
.vc_row {
	&.container{
		margin-left: auto;
		margin-right: auto;

		.container{
			padding: 0;
		}
		.vc_column_container{
			padding-left: 25px;
			padding-right: 25px;
			@media screen and (min-width: $medium) {
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
		.vc_column-inner{
			padding: 0;
		}
	}
	&.middle-border{
		.vc_col-sm-6{
			@media screen and (min-width: $medium) {
				border-left: 1px solid #dde1e4;
				&:nth-child(2n+1) {
					border: none;
				}
			}
		}
	}
}
